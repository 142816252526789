import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import AboutView from "../views/AboutView.vue";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/Auth/Login.vue";
import ResetTokenExpiredView from "../views/Auth/ResetTokenExpiredView.vue";
import ResetPasswordErrorView from "../views/Auth/ResetPasswordErrorView.vue";

async function isAuthenticated(to: any, from: any, next: any) {
  const isChangePasswordError =
    to?.query?.error_description === "please change your password";
  let isAuthGuarded = false;
  if (isChangePasswordError) {
    return next({ name: "reset-password-error" });
  } else {
    isAuthGuarded = await authGuard(to);
    if (isAuthGuarded) return next();
    else return next({ name: "login" });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: isAuthenticated,
  },
  {
    path: "/auth/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/auth/reset-token-expired",
    name: "reset-token-expired",
    component: ResetTokenExpiredView,
  },
  {
    path: "/auth/reset-password-error",
    name: "reset-password-error",
    component: ResetPasswordErrorView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    beforeEnter: isAuthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
