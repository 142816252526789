<template>
  <div>
    <button
      data-testid="header-button"
      class="fixed top-0 right-28 text-white px-[12px] py-[6px] rounded-b-md bg-primary text-xs cursor-pointer hover:text-primary hover:bg-white"
      @click="openFeedBackWidget"
    >
      Feedback
    </button>
  </div>
</template>
<script>
import { onMounted } from "vue";
import { useToast } from "vue-toastification";
import config from "../../../config/config.ts";
import { useAuth0 } from "@auth0/auth0-vue";
export default {
  setup() {
    const auth0 = useAuth0();
    const toast = useToast();
    const options = {
      position: "top-right",
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    };

    onMounted(() => {
      if (config.userSnapGlobalAPIkey) {
        window.onUsersnapCXLoad = function (api) {
          api.init({
            user: {
              email: auth0.user.value.email,
            },
          });
          window.Usersnap = api;
        };
        var script = document.createElement("script");
        script.defer = 1;
        script.src = `https://widget.usersnap.com/global/load/${config.userSnapGlobalAPIkey}?onload=onUsersnapCXLoad`;
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    });
    const openFeedBackWidget = () => {
      if (window.Usersnap == undefined) {
        toast.error("unable to feedback", {
          ...options,
        });

        return;
      }
      window.Usersnap.logEvent("openusersnap");
    };
    return {
      openFeedBackWidget,
    };
  },
};
</script>
