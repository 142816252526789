import config from "@/config/config";
import { get, post, put, del } from "./../utils/apiClient";

interface CreateProjectReqBody {
  name?: string;
  description?: string;
}

interface CreateProjectParams {
  ProjectId?: string;
}
interface EditProjectReqBody {
  name?: string;
  description?: string;
}
interface EditProjectParams {
  ProjectId?: string;
}
interface ShareProjectReqBody {
  email?: string;
}

export const getAllProjectsOfUser = async (params: {
  page: number;
  limit: number;
  searchValue?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  name?: string;
}) => {
  
  if (params.searchValue?.trim() == "") {
    delete params.searchValue;
  }
  if (
    params.startDate == "" ||
    params.startDate == undefined ||
    params.startDate == null
  ) {
    delete params.startDate;
    delete params.endDate;
  }
  
  const response = await get(`/dt-projects`, {
    headers: {},
    params: params,
  });

  return response;
};

export const createProject = async (
  reqBody: CreateProjectReqBody,
  params: CreateProjectParams = {}
) => {
  const response = await post("/dt-projects", reqBody, { params });
  return response;
};

export const deleteProject = async (projectId: string) => {
  const response = await del(`/dt-projects/${projectId}`);
  return response;
};
export const editProject = async (
  projectId: EditProjectParams,
  reqBody: EditProjectReqBody
) => {
  const response = await put(`/dt-projects/${projectId}`, reqBody);
  return response;
};
export const cloneOrShareProject = async (
  projectId: string,
  reqBody: ShareProjectReqBody = {}
) => {
  const response = await post(`/dt-projects/${projectId}`, reqBody);
  return response;
};

