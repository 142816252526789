<template>
  <Loader v-if="isLoading" />
  <div v-else id="app" class="m-0 p-0 h-screen w-screen text-base-100">
    <div class="vp-custom-background"></div>
    <Navbar v-if="layout === 'non-auth'" />
    <div
      :class="[
        layout === 'non-auth' && 'app-wrapper',
        'h-full',
        'overflow-hidden',
      ]"
    >
      <router-view isAuthenticated />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Layout/Default/Navbar.vue";
import Loader from "@/components/Layout/Default/Loader.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: { Navbar, Loader },

  setup() {
    const { isAuthenticated, isLoading, ...rest } = useAuth0();
    const store = useStore();

    let layout = computed(() => {
      return store.state.layout;
    });

    if (window.location.href.indexOf("auth/reset-token-expired") > -1) {
      layout = "auth";
    }

    return {
      isAuthenticated,
      layout,
      isLoading,
    };
  },
};
</script>
