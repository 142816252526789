import configuration from "../../public/config.json";
const primaryConfig = configuration;

const config = {
  port: process.env.VUE_APP_DEV_PORT || 3001,
  userSnapGlobalAPIkey: process.env.VUE_APP_USERSNAP_GLOBAL_API_KEY,
  auth0: {
    domain: primaryConfig?.auth0?.domain || process.env.VUE_APP_AUTH0_DOMAIN,
    clientId:
      primaryConfig?.auth0?.clientId || process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience:
      primaryConfig?.auth0?.audience || process.env.VUE_APP_AUTH0_AUDIENCE_URL,
  },

  designTool: {
    url:
      primaryConfig?.designTool?.url ||
      process.env.VUE_APP_DESIGN_TOOL_BASE_URL,
    summary:
      primaryConfig?.designTool?.summary ||
      process.env.VUE_APP_DESIGN_TOOL_SUMMARY,
  },

  BaseURL: primaryConfig?.baseURL || process.env.VUE_APP_API_BASE_URL,
};

export default config;
