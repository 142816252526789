<template>
  <div class="dropdown dropdown-end">
    <button
      data-testid="filter-button"
      class="hover:bg-[#052a421a] w-10 h-10 rounded-full text-center"
    >
      <img class="m-auto" src="../../../public/img/icons/filter.svg" />
    </button>
    <ul
      data-testid="dropdown"
      tabindex="0"
      class="dropdown-content menu p-2 shadow bg-white rounded-box w-44"
    >
      <div class="form-control m-auto">
        <label
          class="label justify-start cursor-pointer"
          v-for="(option, index) in optionsBeforeDateRange"
          :key="index"
        >
          <input
            data-testid="checkbox"
            type="checkbox"
            :value="option.label"
            :checked="selectedDateFilter == option.label"
            class="accent-[#052A42] w-5 h-5"
            @change="beforeDateCheck($event)"
          />
          <span class="text-neutral text-base ml-4">{{ option.label  }}</span>
        </label>

        <!-- Date Range Picker -->
        <v-date-picker
          data-testid="calender"
          class="customPicker"
          v-if="selectedDateFilter == 'Date Range'"
          v-model="dateRange"
          is-range
        />

        <!-- These are the options for Project Type Selection, on which we are not currently working on -->

        <!-- <div class="divider"></div>

        <label
          class="label justify-start cursor-pointer"
          v-for="option in optionsAfterDateRange"
        >
          <input
            type="checkbox"
            class="accent-[#052A42] w-5 h-5"
            v-model="option.isSelected"
            @change="afterDateCheck()"
            :disabled="option.isDisable"
          />
          <span class="label-text text-base ml-5">{{ option.label }}</span>
        </label> -->
      </div>
    </ul>
  </div>
</template>

<script>
import { ref, reactive, watch, computed, toRef } from "vue";
import moment from "moment";

export default {
  props: {
    filterOptions: Object
  },
  setup(props, context) {

    const savedFilterOptions = toRef(props, 'filterOptions');
    const localFilterOptions = ref({
      startDate: null,
      endDate: null,
      selectedSearchFilterId: '',
      ...savedFilterOptions.value
    }) 
    const optionsBeforeDateRange = reactive([
        { id: 0, label: "This week" },
        { id: 1, label: "This month" },
        { id: 2, label: "Date Range"}
      ])
    
    const dateRange = ref({
      start: localFilterOptions.value.selectedSearchFilterId == "Date Range" ? savedFilterOptions.value.startDate : null,
      end: localFilterOptions.value.selectedSearchFilterId == "Date Range" ? savedFilterOptions.value.endDate : null
    });

    const selectedDateFilter = ref(localFilterOptions.value.selectedSearchFilterId);

    watch(savedFilterOptions, () => {
      localFilterOptions.value = savedFilterOptions.value
      selectedDateFilter.value = savedFilterOptions.value.selectedSearchFilterId
      if (selectedDateFilter.value == "Date Range") {
        dateRange.value = {
          start: localFilterOptions.value.startDate,
          end: localFilterOptions.value.endDate
        }
      }
    });


    watch(selectedDateFilter, () => {
      if (selectedDateFilter.value == savedFilterOptions.value.selectedSearchFilterId) return
      const now = moment().format();
      let computedDateFilter = {
        startDate: null,
        endDate: null,
      };
      const lastWeek = moment().subtract(7, "days").format();
      const lastMonth = moment().subtract(1, "month").format();
      switch (selectedDateFilter.value) {
        case "Date Range":
          computedDateFilter = {
            startDate: dateRange.value.start,
            endDate: dateRange.value.end,
          };
          break;
        case "This week":
          computedDateFilter = {
            startDate: lastWeek,
            endDate: now,
          };
          break;
        case "This month":
          computedDateFilter = {
            startDate: lastMonth,
            endDate: now,
          };
          break;
        default:
          computedDateFilter = {
            startDate: null,
            endDate: null,
          };
          break;
      }
      localFilterOptions.value = {
        ...computedDateFilter,
        selectedSearchFilterId: selectedDateFilter.value
      }

    });

    watch(() => ({...dateRange.value}), () => {
      localFilterOptions.value = {
        ...localFilterOptions.value,
        startDate: dateRange.value.start,
        endDate: dateRange.value.end
      }
    }, {
      deep:true
    })

    watch(() => ({...localFilterOptions.value}), (newVal, oldVal) => {
      if (JSON.stringify(localFilterOptions.value) != JSON.stringify(savedFilterOptions.value)) {
        if (newVal.selectedSearchFilterId == "Date Range" && (!newVal.startDate || !newVal.endDate)) return
        context.emit('onChangeFilter', localFilterOptions.value)
      }
    }, {
      deep: true
    });


    const beforeDateCheck = ($event) => {
      if (!["This week", "This month", "Date Range"].includes($event.target.value)) return
      if ($event.target.checked) {
        selectedDateFilter.value = $event.target.value
      } else {
        selectedDateFilter.value = ""
      }
    };

    return {
      optionsBeforeDateRange,
      beforeDateCheck,
      dateRange,
      localFilterOptions,
      selectedDateFilter
    };
  },
};
</script>
<style scoped>
.customPicker {
  position: absolute;
  right: 180px;
  top: 100px;
}
</style>
