<template>
  <div
    class="main-container relative h-screen w-full lg:block flex items-center justify-center"
  >
    <div
      class="card-box-container z-10 rounded-lg absolute top-1/2 w-96 lg:m-0 ml-0 bg-white"
    >
      <div class="logo flex flex-col items-center">
        <img src="https://dtool.staging.viaphoton.dev/img/logo_with_text.svg" />
      </div>
      <div class="card-box px-10 pt-10 pb-14">
        <div class="card-header flex flex-col items-center">
          <h4 class="text-xl text-black">Reset Password</h4>
          <p class="underline text-amber-500 -mt-3">____________</p>
          <p class="error-message my-3 mb-5 text-neutral">
            Please Reset Your Password to continue
          </p>
        </div>
        <div class="card-footer flex flex-wrap justify-around">
          <button
            type="submit"
            id="btn-login"
            class="border border-amber-500 text-amber-500 px-5 py-2"
          >
            <a href="https://viaphoton.com/">Viaphoton.com</a>
          </button>
          <button
            type="submit"
            id="btn-login"
            class="bg-amber-500 text-white px-5 py-2"
          >
            <a @click="resetPassword">Reset Password</a>
          </button>
        </div>
      </div>
    </div>
    <div class="bg d-sm-none d-md-block absolute left-0 top-0 h-full w-full">
      <div
        class="bg-panel absolute right-0 d-none d-lg-block d-xl-block lg:block hidden"
      >
        <img
          src="https://vdt.win.co.ua/img/login-image.png"
          class="w-max h-auto align-middle"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@media (max-width: 1024px) {
  .card-box-container {
    margin-left: 0% !important;
  }
}
@media (max-width: 1300px) {
  .bg-panel img {
    width: 80% !important;
    float: right;
  }
}

body {
  overflow: hidden;
}

.bg {
  &:before {
    content: "";
    background: #ffffff;
    transform: skew(-30deg);
    position: absolute;
    width: 100%;
    height: 100%;
    left: -25%;
    z-index: 0;
  }
  .bg-panel {
    top: calc(50% - 190px);
  }
}

.main-container {
  background-color: #2d3e52;
  .card-box-container {
    margin-left: 10%;
    transform: translateY(-50%);
    .logo img {
      width: 200px;
      margin-bottom: 1.5rem;
    }
    .card-box {
      box-shadow: 0px 5px 5px #ccc;
      box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
    }
  }
}
</style>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const store = useStore();
    const auth0 = useAuth0();

    function resetPassword() {
      auth0.logout({ returnTo: window.location.origin });
    }

    store.commit("setLayout", "auth");
    return {
      resetPassword,
    };
  },
};
</script>
