<template>
  <div class="border-b-2 flex flex-1 items-center">
    <input
      data-testid="search-bar"
      type="text"
      placeholder="Project name..."
      :value="savedSearchText"
      @input="inputValue"
      class="text-neutral w-full py-1 focus:outline-none bg-transparent"
    />
    <button class="ml-2" data-testid="search-button">
      <img class="w-4 h-4" src="../../../public/img/icons/search.svg" />
    </button>
  </div>
</template>

<script>
import { ref, watch, toRef } from "vue";

export default {
  props: {
    searchText: String
  },
  setup(props, context) {
    const savedSearchText = toRef(props, 'searchText');
    const localSearchText = ref(savedSearchText.value)
    let timer = ref(null)
    const inputValue = (event) => {
      clearTimeout(timer);
      timer = setTimeout(() => (localSearchText.value = event.target.value), 1000);
    };
    watch(savedSearchText, () => {
      localSearchText.value = savedSearchText.value
    });
    watch(localSearchText, () => {
      if(localSearchText.value != savedSearchText.value) {
        context.emit("onSearchText", localSearchText.value);
      }
    });

    return { savedSearchText, inputValue };
  },
};
</script>
