<template>
  <div class="flex flex-row h-full">
    <div class="p-6 w-full">
      <notification
        v-if="updateExists"
        :content="updateNotificationContent(refreshApp)"
        :toastOptions="{
          timeout: false,
          draggable: false,
          closeOnClick: false,
          closeButton: false,
          icon: 'fa-solid fa-circle-info text-primary text-2xl',
        }"
      />
      <div class="text-white py-2 px-3 mb-3 bg-red-500" v-if="errorMessage">
        {{ errorMessage }}
      </div>

      <!-- cards  -->
      <div class="flex flex-wrap gap-y-4 gap-x-7 mb-5">
        <button
          v-for="service in services"
          :key="service.title"
          class="services-card card p-4 h-28 shadow-md border-none bg-white"
        >
          <label
            for="create-project"
            class="mx-auto text-center flex flex-col items-center cursor-pointer modal-button"
          >
            <img
              :src="service.img"
              alt="User's profile picture"
              class="h-8 w-9 mt-1"
            />
            <h2 class="mt-3 text-neutral font-normal text-xs">
              {{ service.title }}
            </h2>
          </label>
        </button>
      </div>
      <div class="flex my-5">
        <searching @onSearchText="setUserSearchText" :searchText="userSearchText" />
        <Sorting @onSort="setSortingMethod" :sortingMethod="sortOption"/>
        <projects-filter @onChangeFilter="setSearchFilter" :filterOptions="searchFilter" />
      </div>
      <Pagination
        ref="paginationRef"
        v-if="projects.length"
        :paginationOptions="paginationOptions"
        @updatePages="onUpdatePages"
      />
      <!-- table  -->
      <div class="flex xl:flex-row flex-col mt-3">
        <div class="py-3 w-full">
          <div
            v-for="project in paginatedProjects"
            :key="project.id"
            class="table-cards break-words md:w-full w-max flex flex-nowrap text-neutral shadow-sm items-center my-3 rounded-xl bg-white"
          >
            <div class="flex-none w-10">
              <img
                src="../assets/img/sitemap1.png"
                alt="User's profile picture"
                class="h-5 w-6"
              />
            </div>
            <div class="flex-none w-72 px-2">
              <span class="text-neutral project-name">
                {{ project.dtProject.name.substring(0, 27) }}
              </span>
              <span
                class="tooltip relative"
                :data-tip="project.dtProject.name"
                v-if="project.dtProject.name.length > 27"
              >
                <button class="text-primary font-bold">&#160;...</button>
              </span>
              <div class="-mt-1">
                <img
                  class="w-3 mr-2 inline"
                  src="../assets/img/calendar-outline.png"
                />
                <span class="project-date">{{
                  $helpers.date_time_format(
                    project.dtProject.createdAt,
                    "MMMM DD, YYYY [at] hh:mm a"
                  )
                }}</span>
              </div>
              <div class="-mt-2">
                <img class="w-3 mr-2 inline" src="../assets/img/clock.png" />
                <span class="project-date">{{
                  $helpers.date_time_format(
                    project.dtProject.updatedAt,
                    "MMMM DD, YYYY [at] hh:mm a"
                  )
                }}</span>
              </div>
            </div>
            <div class="md:flex-1 flex-1 w-72 px-2 text-neutral">
              <div v-if="project.dtProject.description">
                <span class="project-description text-neutral">
                  {{ project.dtProject.description.substring(0, 100) }}
                </span>
                <span
                  class="tooltip relative"
                  :data-tip="project.dtProject.description"
                  v-if="project.dtProject.description.length > 100"
                >
                  <button class="text-primary font-bold">&#160;...</button>
                </span>
              </div>
              <div>
                <span
                  class="block font-normal text-lg project-brief-description"
                  >{{ project.dtProject.detail.networkArchitecture }},
                  {{
                    pluralizeText(
                      "Spine",
                      project.dtProject.detail.numberOfSpines
                    )
                  }},
                  {{
                    pluralizeText(
                      "Leaf",
                      project.dtProject.detail.numberOfLeafs
                    )
                  }},
                  {{
                    pluralizeText(
                      "Server Row",
                      project.dtProject.detail.numberOfServerCabinets.length
                    )
                  }}
                  <span
                    v-if="
                      project.dtProject.detail.numberOfServerCabinets.length
                    "
                  >
                    (
                    <span
                      v-for="(serverRow, index) in project.dtProject.detail
                        .numberOfServerCabinets"
                      :key="index"
                    >
                      {{ pluralizeText("Cabinet", serverRow.noOfCabinets) }}
                      <span
                        v-if="
                          index + 1 <
                          project.dtProject.detail.numberOfServerCabinets.length
                        "
                      >
                        ,
                      </span>
                    </span>
                    )
                  </span>
                </span>
              </div>
            </div>
            <div class="flex-none md:px-0">
              <a
                class="text-neutral mr-4"
                :href="`${projectUrlPrefix}/${project.dtProject.id}`"
              >
                <button class="edit-project-btn font-normal">
                  <img
                    class="w-4 h-4 inline mr-1"
                    src="../assets/img/edit.png"
                  />Edit
                </button>
              </a>
              <div class="dropdown dropdown-end">
                <label tabindex="0" class="" @click="toggleDropdown"
                  ><img
                    class="w-5 relative top-1 mr-2"
                    src="../assets/img/ellipsis-vertical.png"
                /></label>
                <ul
                  v-show="isDropdown"
                  tabindex="0"
                  class="dropdown-content menu p-1 shadow-md bg-white rounded-lg w-40"
                >
                  <li @click="(e) => onCloneProject(e, project.dtProject.id)">
                    <a class="py-2 px-4">
                      <img src="../assets/img/create-copy.png" />
                      <span>Duplicate</span>
                    </a>
                  </li>
                  <hr />

                  <li @click="(e) => onShareProject(project.dtProject.id)">
                    <a>
                      <label
                        for="share-clone"
                        class="text-center flex items-center cursor-pointer modal-button"
                      >
                        <img src="../assets/img/share.png" />
                        <span class="ml-3">Send Copy</span>
                      </label>
                    </a>
                  </li>
                  <hr />
                  <li
                    data-testid="editli"
                    id="editbtn"
                    @click="(e) => setSelectedProject(project.dtProject)"
                  >
                    <label for="edit-project" class="py-2 px-4">
                      <img src="../assets/img/edit.png" style="width: 20px"/>
                      <span>Rename</span>
                    </label>
                  </li>
                  <hr />
                  <li @click="(e) => onDeleteProject(e, project.dtProject.id)">
                    <a class="py-2 px-4">
                      <img src="../assets/img/trash-outline.png" />
                      <span>Delete</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- no record -->
      <div v-if="!isProjectsLoading" class="flex xl:flex-row flex-col">
        <div
          class="no-projects py-3 mt-8 w-full overflow-x-auto overflow-y-hidden font-['Bio_Sans'] text-center"
          v-if="!projects?.length"
        >
          Welcome to the Infrastructure Design Tool!
          <br />
          You don't have any drafts yet. Please start by pressing button above
          to create a new project.
        </div>
      </div>
    </div>
    <Modal ref="createProjectModalRef" :id="'create-project'">
      <form @submit.prevent="onModalFormSubmit" class="modalForm">
        <img
          src="../assets/img/sitemap2.png"
          alt="User's profile picture"
          class="inline-block mr-3 -mt-2"
        />
        <h2 class="mt-1 text-neutral inline-block">New Solution</h2>
        <label class="label pb-0">
          <span class="label-text mt-3 mb-1"
            >Project Name
            <span class="text-primary font-extrabold">*</span></span
          >
        </label>
        <input
          required
          v-model.trim="newProject.name"
          type="text"
          maxlength="255"
          class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
        />
        <label class="label pb-0">
          <span class="label-text text-gray-400 text-sm mt-4 mb-1"
            >Description</span
          >
        </label>
        <textarea
          rows="1"
          v-model.trim="newProject.description"
          @keydown.enter="onTextareaKeyup"
          maxlength="255"
          type="text"
          class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
        ></textarea>
        <div class="flex justify-center mt-1">
          <label
            for="create-project"
            type="text"
            @click="onDraftCancel"
            class="btn button mt-4 border border-primary bg-white text-primary rounded-none px-5 py-2 m-1 modal-button hover:bg-transparent hover:border-primary"
            >CANCEL</label
          >
          <button
            ref="submitButtonRef"
            :class="[
              isLoading ? 'loading' : '',
              'btn button text-white mt-4 bg-primary border rounded-none px-5 py-2 border-primary m-1  hover:bg-primary hover:border-primary',
            ]"
            :disabled="isLoading"
            type="submit"
          >
            CREATE
          </button>
        </div>
      </form>
    </Modal>
    <Modal :id="'share-clone'" ref="shareFormModalRef">
      <ShareForm
        ref="shareForm"
        :isLoading="isLoading"
        @send="onSendEvent"
        @closeModal="onCloseShareFormModal"
      />
    </Modal>
    <Modal ref="editProjectModalRef" :id="'edit-project'">
      <form
        data-testid="editform"
        @submit.prevent="onEditFormSubmit"
        class="modalForm"
      >
        <img
          src="../assets/img/sitemap2.png"
          alt="User's profile picture"
          class="inline-block mr-3 -mt-2"
        />
        <h2 class="mt-1 text-neutral inline-block">Edit Solution</h2>
        <label class="label pb-0" data-testid="projnamelabel">
          <span class="label-text mt-3 mb-1"
            >Project Name
            <span class="text-primary font-extrabold">*</span></span
          >
        </label>
        <input
          data-testid="editname"
          maxlength="255"
          v-model.trim="editedSelectedProject.name"
          type="text"
          class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
          required
        />
        <label class="label pb-0" data-testid="projdescriptionlabel">
          <span class="label-text text-gray-400 text-sm mt-4 mb-1"
            >Description</span
          >
        </label>
        <textarea
          rows="1"
          maxlength="255"
          v-model.trim="editedSelectedProject.description"
          @keydown.enter="onTextareaKeyup"
          type="text"
          class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
        ></textarea>

        <div class="flex justify-center mt-1">
          <label
            for="edit-project"
            type="text"
            @click="onDraftCancel"
            class="btn button mt-4 border border-primary bg-white text-primary rounded-none px-5 py-2 m-1 modal-button hover:bg-transparent hover:border-primary"
            >CANCEL</label
          >
          <button
            ref="submitButtonRef"
            :class="[
              isLoading ? 'loading' : '',
              'btn button text-white mt-4 bg-primary border rounded-none px-5 py-2 border-primary m-1  hover:bg-primary hover:border-primary',
            ]"
            :disabled="
              selectedProject.name == editedSelectedProject.name &&
              selectedProject.description == editedSelectedProject.description
            "
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import Modal from "@/components/Layout/Default/Modal.vue";
import { watch, onMounted, ref, computed } from "vue";
import pluralize from "pluralize";
import config from "../config/config";
import Pagination from "../components/Layout/Default/Pagnination.vue";
import Searching from "../components/DataTable/Searching.vue";
import ProjectsFilter from "../components/DataTable/ProjectsFilter.vue";
import ShareForm from "../components/Layout/Default/Forms/ShareForm.vue";

import {
  createProject,
  getAllProjectsOfUser,
  deleteProject,
  editProject,
  cloneOrShareProject,
} from "../services/projects.services";

import { createOrUpdateUser } from "../services/user.services"
import Notification from "../components/Layout/Default/Notification.vue";
import UpdateNotification from "../components/Slots/Toasts/UpdateNotification.vue";
import Sorting from "../components/DataTable/Sorting.vue";
import { useToast } from "vue-toastification";
import { updateMixin } from "../mixins/update.js";

export default {
  name: "home",
  components: {
    Modal,
    Pagination,
    Notification,
    Sorting,
    Searching,
    ProjectsFilter,
    ShareForm,
  },
  mixins: [updateMixin],
  setup() {
    // ========================= initial values ============================
    const auth0 = useAuth0();
    const defaultDashboardSettings = {
      paginationOptions: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalResults: 0,
      },
      sortingOptions: {
        updatedAt: 'DESC'
      },
      searchingOptions: {},
      filterOptions: {}
    }
    const dashboardSettings = ref(JSON.parse(localStorage.getItem('dashboardSettings'))?.[auth0.user.value.sub] || defaultDashboardSettings) 
    const searchingOptions = ref(dashboardSettings.value.searchingOptions || {})
    const searchFilter = ref({
      ...dashboardSettings.value.filterOptions,
    }) 
    const sortOption = ref({
      ...dashboardSettings.value.sortingOptions
    });
    const paginationOptions = ref({
      ...dashboardSettings.value.paginationOptions
    });

    const userSearchText = ref(searchingOptions.value.name || '');
    const shareForm = ref(null);
    const projectUrlPrefix = `${config.designTool.url}/${config.designTool.summary}`;
    const newProject = ref({ name: "", description: " " });
    const selectedProject = ref({ name: "", description: "" });
    const editedSelectedProject = ref({ name: "", description: "" });
    const projects = ref([]);
    const errorMessage = ref("");
    const shareFormModalRef = ref(null);
    const createProjectModalRef = ref(null);
    const editProjectModalRef = ref(null);
    const paginationRef = ref(null);
    const isDropdown = ref(false);
    const isLoading = ref(false);
    const isProjectsLoading = ref(false);
    const submitButtonRef = ref(null);
    const inputEmail = ref("");
    const projectIdTobeSend = ref("");
    const services = ref([
      {
        title: "Design a new solution",
        img: require("../assets/img/sitemap.png"),
      },
    ]);
    let firstTimeMounted = ref(true)
    // ========================= lifecycle hooks ============================
    onMounted(async () => {
      try {
        isProjectsLoading.value = true;
        await handleDashboardState()
        getProjects(queryParams.value);
        await auth0.getAccessTokenSilently();
      } catch (error) {
        console.log(error.message);
      }
    });

    // ========================= Computed ============================
    const queryParams = computed(() => {
      return {
        page: paginationOptions.value.page,
        limit: paginationOptions.value.limit,
        searchValue: userSearchText.value,
        ...searchFilter.value,
        ...sortOption.value,
      }
    });

    // ========================= Watchers ============================
    watch(queryParams, async () => {
      if (!firstTimeMounted.value) {
        const dashboardSettings = {
          [auth0.user.value.sub]: {

            paginationOptions: {...paginationOptions.value},
            sortingOptions: { ...sortOption.value },
            filterOptions: { ...searchFilter.value },
            searchingOptions: { ...searchingOptions.value, name: userSearchText.value}
          }
        }

        localStorage.setItem('dashboardSettings', JSON.stringify(dashboardSettings))
        getProjects(queryParams.value);
      }
    });

    // ========================= methods ============================
    const pluralizeText = (textToBePluralize, count) => {
      return count > 1
        ? `${count} ${textToBePluralize}s`
        : `${count} ${textToBePluralize}`;
    };

    const onModalFormSubmit = async () => {
      isLoading.value = true;
      const response = await createProject(newProject.value);
      if (!response?.success) {
        errorMessage.value = response.message;
        isLoading.value = false;
        createProjectModalRef.value.closeModal();
      } else {
        const { id } = response?.data;
        const redirectURL = `${config.designTool.url}/${config.designTool.summary}/${id}`;
        window.location = redirectURL;
        paginationOptions.value.page + 1;
      }
    };

    const setSelectedProject = (dtProj) => {
      selectedProject.value = dtProj;
      editedSelectedProject.value = {
        name: selectedProject.value.name,
        description: selectedProject.value.description,
      };
    };
    const onEditFormSubmit = async () => {
      var obj = {};
      if (editedSelectedProject.value.name != "") {
        obj.name = editedSelectedProject.value.name;
      }
      if (editedSelectedProject.value.description != "") {
        obj.description = editedSelectedProject.value.description;
      }
      isLoading.value = true;
      const response = await editProject(selectedProject.value.id, obj);
      if (!response?.success) {
        errorMessage.value = response.message;
        isLoading.value = false;
        editProjectModalRef.value.closeModal();
      } else {
        await getProjects(queryParams.value);
        isLoading.value = false;
        editProjectModalRef.value.closeModal();
      }
    };

    const onCloseShareFormModal = () => {
      shareFormModalRef.value.closeModal();
    };
    const onUpdatePages = (e) => {
      paginationOptions.value.page = e || 1;
    };

    const onDeleteProject = async (event, projectID) => {
      isProjectsLoading.value = true;
      const response = await deleteProject(projectID);
      if (!response?.success) {
        errorMessage.value = response.message;
      } else {
        event.target.blur();
        if (projects.value?.length <= 1) {
          paginationOptions.value.totalPages -= 1;
          paginationRef.value.goToPage(paginationOptions.value.page - 1);
        }
        isDropdown.value = false;
      }
      getProjects(queryParams.value);
      isProjectsLoading.value = false;
    };

    const onCloneProject = async (event, projectId) => {
      isProjectsLoading.value = true;

      const response = await cloneOrShareProject(projectId);
      if (!response?.success) {
        errorMessage.value = response.message;
      } else {
        event.target.blur();
        isDropdown.value = false;
      }
      getProjects(queryParams.value);
      isProjectsLoading.value = false;
    };
    const onShareProject = async (projectId) => {
      isDropdown.value = false;
      projectIdTobeSend.value = projectId;
      shareForm.value.resetForm();
    };
    const onSendEvent = async (e) => {
      isLoading.value = true;
      const email = e;
      const projectId = projectIdTobeSend.value;
      const response = await cloneOrShareProject(projectId, { email });
      isLoading.value = false;

      if (!response?.success) {
        toast.error(response.data.message, {
          ...options,
        });
        return;
      }

      toast.success("Copy sent successfully", {
        ...options,
      });
      shareFormModalRef.value.closeModal();
    };

    const logout = () => {
      auth0.logout({ returnTo: window.location.origin });
    };

    const handleDashboardState = async () =>  {
        const dataToCreateUser = {
          id: auth0.user.value.sub,
          email: auth0.user.value.email,
          emailVerified: auth0.user.value.email_verified,
          name: auth0.user.value.name
        }

        const response = await createOrUpdateUser(dataToCreateUser)
        if (response.success) {
          // set pagination options
          paginationOptions.value = {
            ...paginationOptions.value,
            ...response.dashboardSettings?.paginationOptions
          }

          // set searching options
          userSearchText.value = (response.data.dashboardSettings?.searchingOptions && response.data.dashboardSettings?.searchingOptions['name']) || ''
          searchingOptions.value = {
            ...searchingOptions.value,
            ...response.data.dashboardSettings?.searchingOptions
          }

          // set filter options

          searchFilter.value = {
            ...searchFilter.value,
            ...response.data.dashboardSettings?.filterOptions
          }

          // set sorting options
          sortOption.value = {
            ...sortOption.value,
            ...response.data.dashboardSettings?.sortingOptions
          }
          const dashboardSettings = {
            [auth0.user.value.sub]: {
              ...response.data.dashboardSettings
            }
          }
          localStorage.setItem('dashboardSettings', JSON.stringify(dashboardSettings))
        
        }
    }

    const getProjects = async (queryParams) => {
      isProjectsLoading.value = true; 
      const data = await getAllProjectsOfUser(queryParams);
      if (!data?.success) {
        errorMessage.value = data.message;
      } else {
        const { results, ...pagination } = data?.data;
        projects.value = results;
        paginationOptions.value["hasNextPage"] = pagination.hasNextPage;
        paginationOptions.value["hasPrevPage"] = pagination.hasPrevPage;
        paginationOptions.value["limit"] = pagination.limit;
        paginationOptions.value["totalResults"] = pagination.totalResults;
        paginationOptions.value["totalPages"] = pagination.totalPages;
      }
      isProjectsLoading.value = false;
      firstTimeMounted.value = false
    };

    const setSearchFilter = (filter) => {
      searchFilter.value = {
        ...searchFilter.value,
        ...filter
      }
    };

    const setUserSearchText = (text) => {
      userSearchText.value = text.trim();
      if (userSearchText.value) {
        paginationOptions.value.page = 1;
      }
      paginationRef.value?.goToPage(paginationOptions.value.page);
    };

    const paginatedProjects = computed(() => {
      let projectsList = projects.value.slice(
        0,
        paginationOptions.value.limit
      );
      return projectsList;
    });

    const onDraftCancel = () => {
      isLoading.value = false;
    };

    const toggleDropdown = () => {
      isDropdown.value = !isDropdown.value;
    };

    const onTextareaKeyup = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        submitButtonRef.value.click();
      }
    };

    const setSortingMethod = (sortFilter) => {
      sortOption.value = sortFilter;
    };

    // Created this method because the mixins' constants/functions couldn't be accessed in script.
    // In this case we have to pass refreshApp method to the Update Notification Component.
    const updateNotificationContent = (refresh) => {
      return {
        component: UpdateNotification,
        props: {
          refresh: refresh,
        },
      };
    };
    const toast = useToast();
    const options = {
      position: "top-right",
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    };

    return {
      setSearchFilter,
      searchFilter,
      setUserSearchText,
      userSearchText,
      setSortingMethod,
      sortOption,
      user: auth0.user,
      services,
      auth0,
      projects,
      newProject,
      errorMessage,
      projectUrlPrefix,
      paginationOptions,
      paginatedProjects,
      isDropdown,
      isLoading,
      isProjectsLoading,
      shareFormModalRef,
      createProjectModalRef,
      paginationRef,
      submitButtonRef,
      inputEmail,
      projectIdTobeSend,
      shareForm,
      onSendEvent,
      toggleDropdown,
      onUpdatePages,
      logout,
      onDeleteProject,
      onCloneProject,
      onDraftCancel,
      onModalFormSubmit,
      onTextareaKeyup,
      updateNotificationContent,
      onShareProject,
      onCloseShareFormModal,
      editProjectModalRef,
      selectedProject,
      editedSelectedProject,
      onEditFormSubmit,
      setSelectedProject,
      pluralizeText
    };
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Splash&display=swap");

form {
  font-family: "Montserrat", sans-serif;
}

.tooltip::before {
  font-size: 13px;
  color: #ededed;
}

.services-card {
  width: 132px;
  border-radius: 10px;

  h2 {
    font-family: "Archivo";
  }
}

.table-cards {
  padding: 12.5px;

  .project-name {
    font-size: 14.5px;
    font-family: "Archivo";
    line-height: 19px;
  }

  .project-description {
    font-size: 14.5px;
    font-family: "Bio Sans", sans-serif;
    line-height: 19px;
  }

  .project-brief-description {
    color: #79899b;
    font-size: 14px;
    font-family: "Bio Sans", sans-serif;
  }

  .project-date {
    font-size: 10px;
    color: #757575;
    font-family: "Bio Sans", sans-serif;
  }

  .edit-project-btn {
    width: 68px;
    height: 36px;
    border: 1px solid #052a42;
    border-radius: 10px;
    font-family: "Archivo";
    font-size: 14.5px;
    line-height: 16px;
  }

  .dropdown-content span {
    font-family: "Archivo";
    font-size: 17px;
  }
}

.no-projects {
  color: #79899b;
  font-size: 15px;
}

@media (max-width: 768px) {
  .table-cards {
    width: 100vh !important;
  }
}
</style>
