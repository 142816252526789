<template>
  <form
    ref="form"
    @submit.prevent="onFormSubmit"
    class="modalForm"
    data-testid="form-tag"
  >
    <h2 class="mt-1 text-neutral inline-block">Send copy to</h2>
    <label class="label pb-0">
      <span class="label-text mt-3 mb-1"
        >User e-mail<span class="text-primary font-extrabold">*</span></span
      >
    </label>
    <input
      data-testid="input-tag"
      v-model="inputEmail"
      required
      type="email"
      maxlength="255"
      class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
    />
    <div class="flex justify-center mt-1">
      <label
        for="share-clone"
        class="btn button mt-4 border border-primary bg-white text-primary rounded-none px-5 py-2 m-1 modal-button hover:bg-transparent hover:border-primary"
        >CANCEL</label
      >
      <button
        data-testid="button-tag"
        :class="[
          isLoading ? 'loading' : '',
          'btn button text-white mt-4 bg-primary border rounded-none px-5 py-2 border-primary m-1  hover:bg-primary hover:border-primary',
        ]"
        :disabled="isLoading"
        type="submit"
      >
        SEND
      </button>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    isLoading: Boolean,
  },

  setup(props, context) {
    const form = ref();
    const inputEmail = ref("");
    const onFormSubmit = () => {
      context.emit("send", inputEmail.value);
    };
    const onDraftCancel = () => {
      context.emit("closeModal");
    };

    const resetForm = () => {
      form.value.reset();
    };

    return {
      onFormSubmit,
      inputEmail,
      form,
      resetForm,
      onDraftCancel,
    };
  },
};
</script>
