<template>
  <form
    ref="resetPasswordForm"
    @submit.prevent="onFormSubmit"
    class="modalForm"
    data-testid="ResetPasswordform-tag"
  >
    <h2 class="mt-1 text-neutral inline-block">Change Password</h2>
    <label class="label pb-0">
      <span class="label-text mt-3 mb-1">New password</span>
    </label>
    <input
      data-testid="inputPassword-tag"
      v-model.trim="inputPassword"
      required
      type="password"
      maxlength="255"
      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
      class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
    />
    <label class="label pb-0">
      <span class="label-text mt-3 mb-1">Confirm password</span>
    </label>
    <input
      data-testid="input-tag"
      v-model="confirmPassword"
      ref="confirmField"
      required
      type="password"
      maxlength="255"
      @focus="onFocusOrBlur"
      @blur="onFocusOrBlur"
      class="text-lg pl-1 bg-transparent rounded-none w-full text-gray-700 focus:outline-none active:outline-none border-0 border-b border-gray-400 font-thin"
    />
    <p
      v-if="showError && isConfirmPasswordFieldOutOfFocus"
      class="text-sm text-red-500 mt-2"
    >
      *password not match
    </p>
    <div class="flex justify-center mt-1">
      <label
        for="resetPasswordModal"
        class="btn button mt-4 border border-primary bg-white text-primary rounded-none px-5 py-2 m-1 modal-button hover:bg-transparent hover:border-primary"
        >CANCEL</label
      >
      <button
        data-testid="button-tag"
        :class="[
          isLoading ? 'loading' : '',
          'btn button text-white mt-4 bg-primary border rounded-none px-5 py-2 border-primary m-1  hover:bg-primary hover:border-primary',
        ]"
        :disabled="
          isLoading || inputPassword !== confirmPassword || inputPassword == ''
        "
        type="submit"
      >
        Change Password
      </button>
    </div>
  </form>
</template>

<script>
import { onMounted, ref, watch } from "vue";
export default {
  props: {
    isLoading: Boolean,
  },
  setup(props, context) {
    const confirmField = ref(null);
    const inputPassword = ref(null);
    const resetPasswordForm = ref(null);
    const confirmPassword = ref(null);
    const isConfirmPasswordFieldOutOfFocus = ref(false);
    const showError = ref(false);
    watch(confirmPassword, () => {
      inputPassword.value !== confirmPassword.value
        ? (showError.value = true)
        : (showError.value = false);
    });
    const onFormSubmit = () => {
      context.emit("send", {
        inputPassword: inputPassword.value,
        confirmPassword: confirmPassword.value,
      });
    };
    const resetForm = () => {
      resetPasswordForm.value.reset();
      inputPassword.value = "";
      confirmPassword.value = "";
      showError.value = false;
    };
    const onFocusOrBlur = () => {
      if (document.activeElement === confirmField.value) {
        isConfirmPasswordFieldOutOfFocus.value = false;
        return;
      }
      isConfirmPasswordFieldOutOfFocus.value = true;
    };
    return {
      resetPasswordForm,
      inputPassword,
      confirmPassword,
      showError,
      confirmField,
      isConfirmPasswordFieldOutOfFocus,
      onFormSubmit,
      resetForm,
      onFocusOrBlur,
    };
  },
};
</script>
