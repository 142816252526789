import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createAuth0 } from "@auth0/auth0-vue";
import config from "./config/config";
import "./assets/scss/main.scss";
import { helpersPlugin } from "./plugins";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import "v-calendar/dist/style.css";
import VCalendar from "v-calendar";

const app = createApp(App);

// Vue Toastification
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
};

app
  .use(store)
  .use(router)
  .use(helpersPlugin)
  .use(Toast, options)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VCalendar, {})
  .use(
    createAuth0({
      domain: config.auth0.domain,
      client_id: config.auth0.clientId,
      redirect_uri: window.location.origin,
      audience: config.auth0.audience,
    })
  )
  .mount("#app");
