<template>
  <div class="navbar bg-base-100 bg-neutral text-neutral-content fixed z-50">
    <div class="navbar-start">
      <!-- <div class="dropdown">
        <label tabindex="0" class="btn btn-ghost lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </label>
        <ul
          tabindex="0"
          class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 text-neutral rounded-box w-52"
        >
          <li><a>Item 1</a></li>
        </ul>
      </div> -->
      <img
        class="logo ml-2"
        src="@/assets/img/logo-dark.svg"
        alt="viaPhoton Logo"
      />
    </div>
    <div class="navbar-center">
      <span class="text-lg text-white navbar-title font-semibold"
        >HyperMap Dashboard</span
      >
      <FeedBack />
    </div>
    <div class="navbar-end mr-1" v-if="user">
      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar w-10">
          <div class="rounded-full">
            <img class="w-14" :src="user.picture" />
          </div>
        </label>
        <ul
          tabindex="0"
          :class="[
            !user.email ? 'w-60' : 'w-auto',
            'p-2 shadow-lg menu menu-compact dropdown-content bg-white rounded-md text-neutral',
          ]"
        >
          <div
            :class="[
              user.email ? 'hero-content pl-6 pr-6' : 'p-0',
              'flex flex-row',
            ]"
          >
            <img
              :src="user.picture"
              :class="[!user.email ? 'mr-2' : '', 'w-7 rounded-box shadow-2xl']"
            />
            <div>
              <h1 class="username font-normal">{{ user?.name }}</h1>
              <p class="email font-normal">{{ user?.email }}</p>
            </div>
          </div>

          <hr class="mt-1" />
          <label for="resetPasswordModal">
            <li
              @click="changePasswordOption"
              class="text-sm text-neutral p-2 pt-3 cursor-pointer logout"
            >
              Change Password
            </li>
          </label>
          <li
            @click="logout"
            class="text-sm text-neutral p-2 pt-3 cursor-pointer logout"
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
    <Modal :id="'resetPasswordModal'" ref="resetPasswordModalref">
      <ResetPasswordForm
        ref="PasswordResetForm"
        @send="changePassword"
        :isLoading="isLoading"
      />
    </Modal>
  </div>
</template>
<script>
import { ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import FeedBack from "./FeedBack.vue";
import Modal from "@/components/Layout/Default/Modal.vue";
import ResetPasswordForm from "../Default/Forms/ResetPasswordForm.vue";
import { resetPassword } from "../../../services/user.services";
import { useToast } from "vue-toastification";
import config from "../../../config/config"
export default {
  name: "home",
  components: {
    FeedBack,
    Modal,
    ResetPasswordForm,
  },
  setup() {
    const auth0 = useAuth0();
    const resetPasswordModalref = ref(null);
    const PasswordResetForm = ref(null);
    const toast = useToast();
    const isLoading = ref(false);
    const options = {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    };
    const changePassword = async (e) => {
      if (e.inputPassword == e.confirmPassword) {
        isLoading.value = true;
        const password = e.inputPassword;
        const response = await resetPassword({ password });
        isLoading.value = false;
        if (!response.success) {
          toast.error("Something went wrong", {
            ...options,
          });
          return;
        }
        toast.success("Password Changed Successfully", {
          ...options,
        });
        resetPasswordModalref.value.closeModal();
      }
    };
    const changePasswordOption = () => {
      PasswordResetForm.value.resetForm();
    };

    return {
      user: auth0.user,
      auth0,
      logout: () => {
        auth0.logout({ returnTo: window.location.origin });
        window.location = `${config.designTool.url}/logout`
      },
      resetPasswordModalref,
      isLoading,
      changePassword,
      changePasswordOption,
      PasswordResetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;
  .logo {
    width: 137.5px;
  }
  .navbar-title {
    font-family: "Archivo", sans-serif;
    font-style: normal;
    letter-spacing: 0.7px;
    font-size: 16px;
  }
  .username {
    font-size: 14.5px;
    font-family: "Archivo";
  }
  .email {
    font-size: 12.5px;
    color: #757575;
    font-family: "Bio Sans", sans-serif;
  }
  .logout {
    font-family: "Archivo", sans-serif;
  }
}
</style>
