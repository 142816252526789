<template>
  <div class="flex items-center justify-center">
    <span>New app update available!</span>
    <span class="btn btn-sm btn-primary ml-5" @click.stop="refresh"
      >Refresh Now</span
    >
  </div>
</template>

<script>
export default {
  props: {
    refresh: { type: Function },
  },
};
</script>
