import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { useAuth0 } from "@auth0/auth0-vue";
import config from "../config/config";

const apiUrl = `${config.BaseURL}/api/v1`;
const axiosApi = axios.create({ baseURL: apiUrl });

// ============================================= Interfaces =============================================
interface Get {
  (
    url: string,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean
  ): Promise<unknown>;
}

interface Del {
  (
    url: string,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean
  ): Promise<unknown>;
}

interface Post {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean
  ): Promise<unknown>;
}

interface Put {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean
  ): Promise<unknown>;
}
interface Patch {
  (
    url: string,
    reqBody: object,
    config?: {
      headers?: object;
      params?: object;
    },
    hasAuth?: boolean
  ): Promise<unknown>;
}
// ============================================= Interfaces =============================================
let auth0: any;
// ============================================= Helper functions =============================================
async function getToken() {
  if (!auth0) auth0 = useAuth0();
  const token = await auth0.getAccessTokenSilently();
  const useridtoken = auth0.idTokenClaims.value.__raw;
  return { token, useridtoken };
}

// ============================================= Helper functions =============================================

export const get: Get = async function (url, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const { token, useridtoken } = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
          useridtoken,
        },
      };
    }
    const { data } = await axiosApi.get(url, {
      ...config,
    } as AxiosRequestHeaders);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.data || err;
  }
};

export const post: Post = async function (
  url,
  reqBody,
  config = {},
  hasAuth = true
) {
  try {
    if (hasAuth) {
      const { token, useridtoken } = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
          useridtoken,
        },
      };
    }
    const { data } = await axiosApi.post(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.data || err;
  }
};

export const put: Put = async function (
  url,
  reqBody,
  config = {},
  hasAuth = true
) {
  try {
    if (hasAuth) {
      const { token, useridtoken } = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
          useridtoken,
        },
      };
    }
    const { data } = await axiosApi.put(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.data || err;
  }
};
export const patch: Patch = async function (
  url,
  reqBody,
  config = {},
  hasAuth = true
) {
  try {
    if (hasAuth) {
      const { token, useridtoken } = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
          useridtoken,
        },
      };
    }
    const { data } = await axiosApi.patch(url, { ...reqBody }, {
      ...config,
    } as AxiosRequestHeaders);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.data || err;
  }
};
export const del: Del = async function (url, config = {}, hasAuth = true) {
  try {
    if (hasAuth) {
      const { token, useridtoken } = await getToken();
      config = {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
          useridtoken,
        },
      };
    }
    const { data } = await axiosApi.delete(url, {
      ...config,
    } as AxiosRequestHeaders);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.data || err;
  }
};
