<template>
  <div class="flex justify-end">
    <button
      class="text-neutral font-semibold m-2 text-sm"
      :disabled="!paginationOptions.hasPrevPage"
      @click="goToPage(1)"
    >
      First
    </button>

    <button
      :disabled="currentPage <= 1"
      class="btn-outline border hover:bg-transparent hover:text-neutral text-xl border-neutral normal-case rounded-2xl text-neutral m-2 left-btn"
      @click="goToPage(currentPage - 1)"
    >
      <i class="fas fa-angle-left"></i>
    </button>
    <button
      class="bg-transparent"
      v-for="item in paginationOptions.totalPages"
      :key="item"
      :value="item"
      @click="goToPage(item)"
    >
      <!-- Block 1 Start 
      first page: fixed
      ... is dynamic
        ... displays
              * after first page (button) and
              * if current page (selected page is greater than 3) and
              * if total pages (buttons) are greater than 5
       -->
      <span
        ref="paginationBtn"
        :class="`btn${item}-`"
        class="btn-outline border font-semibold hover:bg-transparent hover:text-neutral border-neutral normal-case rounded-2xl text-neutral m-2 px-3.5 py-2.5 centered-btns bg-transparent"
        v-if="item === 1"
      >
        {{ item }}
      </span>
      <span
        v-if="item === 1 && currentPage > 3 && paginationOptions.totalPages > 5"
        class="text-gray-400"
      >
        ...
      </span>
      <!-- Block 1 End -->

      <!-- Block 2 Start 
      contains all pages between first and last
      show items from left offset to right offset values
      -->
      <span
        v-if="
          item !== paginationOptions.totalPages && // not last page
          item !== 1 && // not first page
          item >= leftOffset &&
          item <= rightOffset
        "
        ref="paginationBtn"
        :class="`btn${item}-`"
        class="btn-outline border font-semibold hover:bg-transparent hover:text-neutral border-neutral normal-case rounded-2xl text-neutral m-2 px-3.5 py-2.5 centered-btns bg-transparent"
      >
        {{ item }}
      </span>
      <!-- Block 2 End -->

      <!-- Block 3 Start 
      last page: fixed
      ... is dynamic
        ... displays
          * after first page (button) and
          * if current page (selected page is less than 2nd last and 3rd last pages) and
          * if total pages (buttons) are greater than 5
      -->
      <span
        v-if="
          item === paginationOptions.totalPages &&
          currentPage < paginationOptions.totalPages - 2 &&
          paginationOptions.totalPages > 5
        "
        class="text-gray-400"
      >
        ...
      </span>
      <span
        ref="paginationBtn"
        :class="`btn${item}-`"
        class="btn-outline border font-semibold hover:bg-transparent hover:text-neutral border-neutral normal-case rounded-2xl text-neutral m-2 px-3.5 py-2.5 centered-btns bg-transparent"
        v-if="
          item === paginationOptions.totalPages &&
          paginationOptions.totalPages > 1
        "
      >
        {{ item }}
      </span>
      <!-- Block 3 End -->
    </button>

    <button
      :disabled="currentPage >= paginationOptions.totalPages"
      class="btn-outline border hover:bg-transparent hover:text-neutral border-neutral normal-case py-0 text-xl rounded-2xl text-neutral m-2 right-btn"
      @click="goToPage(currentPage + 1)"
    >
      <i class="fas fa-angle-right"></i>
    </button>
    <button
      :disabled="!paginationOptions.hasNextPage"
      class="text-neutral font-semibold m-2 text-sm"
      @click="goToPage(paginationOptions.totalPages)"
    >
      Last
    </button>
  </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
export default {
  props: {
    page: Number,
    pages: Number,
    paginationOptions: Object,
  },
  components: {},
  setup(props, context) {
    // ==================== variables =========================
    const paginationOptionsPage = ref(props.paginationOptions.page);
    const currentPage = computed({
      get: () => {
        return paginationOptionsPage.value;
      },
      set: (newValue) => {
        paginationOptionsPage.value = newValue;
      },
    });

    const paginationBtn = ref(null);
    const leftOffset = ref(0);
    const rightOffset = ref(4);

    // ==================== watchers =========================
    watch(currentPage, () => {
      addStyles(`btn${currentPage.value}-`);
    });

    // ==================== lifecycles =========================
    onMounted(() => {
      if (paginationBtn.value) {
        addStyles(`btn${currentPage.value}-`);
      }
    });

    // ==================== methods =========================
    const updateOffset = () => {
      if (currentPage.value <= 2) {
        leftOffset.value = 0;
        rightOffset.value = 4;
      } else if (currentPage.value >= props.paginationOptions.totalPages - 1) {
        leftOffset.value = props.paginationOptions.totalPages - 3;
        rightOffset.value = props.paginationOptions.totalPages;
      } else {
        leftOffset.value = currentPage.value - 1;
        rightOffset.value = currentPage.value + 1;
      }
    };

    const goToPage = (id) => {
      if (currentPage.value === id) return;
      currentPage.value = id;
      updateOffset();
      context.emit("updatePages", currentPage.value);
    };

    const addStyles = (btnClass) => {
      Object.values(paginationBtn.value).forEach((element) => {
        if (element.className.search(btnClass) !== -1) {
          element.className += " Page-active";
        } else {
          element.className = element.className.replace(" Page-active", "");
        }
      });
    };

    return {
      paginationBtn,
      currentPage,
      goToPage,
      leftOffset,
      rightOffset,
    };
  },
};
</script>

<style lang="scss" scoped>
.Pagination {
  display: flex;
  justify-content: end;
  .Page,
  .Page-active {
    background-color: transparent;
    border: 1.8px solid #022a41;
    color: #022a41;
    border-radius: 0.8rem;
    padding: 1rem;
    font-weight: 800;
  }
  .PaginationControl {
    padding: 0.4rem;
    border-radius: 0.8rem;
    border: 1.8px solid #022a41;
    margin: 3px;
  }
  .Page-active {
    background-color: #022a41 !important;
    color: white !important;
  }
}
.Page-active {
  background-color: #022a41 !important;
  color: white !important;
}
button[disabled] {
  color: rgb(187, 187, 187) !important;
}
i {
  line-height: 0 !important;
  padding: 1.03rem 0.8rem 1.029rem 0.8rem !important;
}
.m-2 {
  margin: 0.4rem !important;
}
.rounded-2xl {
  border-radius: 13.5px !important;
}
.centered-btns {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 12px;
}
.left-btn,
.right-btn {
  position: relative;
  top: 1.2px;
  padding: 2.5px 12px 2.5px 12px;
}
</style>
