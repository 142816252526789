<template>
  <div
    data-testid="sort-div"
    class="text-neutral border-b-2 flex items-center mx-2"
  >
    <img class="w-4 h-4" src="../../../public/img/icons/Frame.svg" />
    <select
      data-testid="sort-select"
      id="mySelect"
      ref="selectRef"
      class="bg-transparent w-full p-[4px] text-neutral focus:outline-none tracking-normal"
      v-model="selectedOption"
      @change="onSortSelect"
    >
      <option
        class="text-neutral"
        :value="data.filter"
        v-for="(data, index) in sortOptions"
        :key="index"
      >
        {{ data.label }}
      </option>
    </select>
  </div>
</template>
<script>
import { watch, ref, toRef } from "vue";
export default {
  props: {
    sortingMethod: Object
  },
  setup(props, context) {
    const selectRef = ref(null);
    const sortingMethod = toRef(props, 'sortingMethod')
    const selectedOption = ref(sortingMethod.value);
    const sortOptions = ref([
      { filter: { name: "ASC" }, label: "Name - ascending" },
      { filter: { name: "DESC" }, label: "Name - descending" },
      { filter: { createdAt: "ASC" }, label: "Created on - ascending" },
      { filter: { createdAt: "DESC" }, label: "Created on - descending" },
      { filter: { updatedAt: "ASC" }, label: "Updated on - ascending" },
      { filter: { updatedAt: "DESC" }, label: "Updated on - descending" },
    ]);
    
    watch(sortingMethod, () => {
      selectedOption.value = sortingMethod.value
    });
    watch(selectedOption, () => {
      if (selectedOption.value != sortingMethod.value) {
        context.emit("onSort", selectedOption.value);
      }
    });

    return {
      selectedOption,
      selectRef,
      sortOptions,
    };
  },
};
</script>
<style lang="scss" scoped>
#mySelect {
  font-family: "Bio Sans";
  font-style: normal;
}
</style>
