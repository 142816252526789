import { createStore } from "vuex";

export default createStore({
  state: {
    layout: "non-auth", // non-auth, auth
  },
  getters: {},
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
  },
  actions: {},
  modules: {},
});
