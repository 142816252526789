<template>
  <div></div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  props: {
    content: {
      required: true,
    },
    toastOptions: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const toast = useToast();

    const defaultOptions = {
      type: "default",
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false,
    };

    const options = {
      ...defaultOptions,
      ...props.toastOptions,
    };

    toast(props.content, {
      toastClassName: options.type !== "default" ? "tailwind" : "defaultColor",
      ...options,
    });
  },
};
</script>

<style>
.Vue-Toastification__toast--default.defaultColor {
  @apply bg-neutral shadow-xl w-3/4;
}
.Vue-Toastification__toast--default.tailwind {
  @apply shadow-xl w-3/4;
}
.Vue-Toastification__container.top-right {
  @apply pt-16;
}
</style>
