import config from "@/config/config";
import { get, post, put, del } from "./../utils/apiClient";
interface ResetPasswordReqBody {
  password?: string;
}

interface CreateUserReqBody {
  id: string;
  email: string;
  email_verified: boolean;
  dashboardSettings?: object;
}

export const resetPassword = async (reqBody: ResetPasswordReqBody = {}) => {
  const response = await put("users/reset-password", reqBody);
  return response;
};

export const createOrUpdateUser = async (reqBody: CreateUserReqBody) => {
  const response = await post(`/users`, reqBody);
  return response;
};
