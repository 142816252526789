<template>
  <div>
    <input ref="modalRef" type="checkbox" :id="id" class="modal-toggle" />
    <label :for="id" class="modal cursor-pointer">
      <label class="modal-box relative rounded-none bg-white" for="">
        <label
          :for="id"
          class="btn font-extralight btn-sm btn-circle absolute right-3 top-3 text-neutral bg-transparent hover:bg-transparent border-0 text-3xl"
          >✕</label
        >
        <slot></slot>
      </label>
    </label>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    id: String,
  },
  setup() {
    const modalRef = ref(null);
    const closeModal = () => {
      modalRef.value.checked = false;
    };
    return { modalRef, closeModal };
  },
};
</script>
